import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Divider,
  darken,
  Tabs,
  Tab,
  IconButton,
  TextField,
  InputAdornment
} from "@mui/material";
import {
  Close as CloseIcon,
} from "@mui/icons-material";
import CopiedTooltip from "../components/common/CopiedTooltip";
import { openModal, closeModal } from "../features/slices/modalSlice";
import FlipNumbers from "react-flip-numbers";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import { selectCurrentToken } from "../features/slices/authSlice";

import Image from "../components/common/Image";
import Announcement from "../components/common/Announcement";
import ImageCarousel from "../components/common/ImageCarousel";
import CardCarousel from "../components/common/CardCarousel";
import TextCarousel from "../components/common/TextCarousel";
import Backdrop from "../components/common/Backdrop";
import AccordionComponent from "../components/common/Accordion";

import SlotIcon from "../icons/header/SlotIcon";

import {
  useGetGameCategoryQuery,
  useLazyGetGameProviderQuery,
  useGetGameProviderQuery,
  useLazyGetLaunchGameQuery
} from "../features/api/gameApiSlice";
import { useGetHomePageQuery } from "../features/api/homeApiSlice";
import { useGetShareLinkQuery } from "../features/api/userApiSlice";

import CasinoIcon from "../icons/header/CasinoIcon";
import SportIcon from "../icons/header/SportIcon";
import FishingIcon from "../icons/header/FishingIcon";
import PromotionIcon from "../icons/header/PromotionIcon";
import VipIcon from "../icons/header/VipIcon";
import WalletCard from "../components/common/WalletCard";
import SabungIcon from "../icons/header/SabungIcon";
import LiveCasinoCarousel from "../components/common/LiveCasinoCarousel";
import DOMPurify from "dompurify";

import * as Constant from "../features/constant";

const PATH = process.env.PUBLIC_URL;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Home = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [popupOpen, setPopupOpen] = useState(true);
  const [affiliatePopupOpen, setAffiliatePopupOpen] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedGameCategory, setSelectedGameCategory] = useState("");
  const [mobileSelectedGameCategory, setMobilSelectedGameCategory] =
    useState("SLOT");
  const isLoggedIn = useSelector(selectCurrentToken);
  const [number, setNumber] = useState(14020000);
  const [gameShownValue, setGameShownValue] = useState(0);
  const lang = localStorage.getItem("language");

  const additionalGameBarItems = [
    { label: t("home.promotion"), code: "promotion" },
    { label: t("home.vip.title"), code: "vip" },
  ];

  const walletCardScreens = [
    {
      label: t("walletcard.deposit"),
      code: "Deposit",
      icon: (
        <Image
          width="40px"
          height="40px"
          src={PATH + `/assets/img/wallet/wallet_card/Deposit.png`}
          alt={Constant.ALT_TAG_ICON_DEPOSIT}
        />
      ),
    },
    {
      label: t("walletcard.withdrawal"),
      code: "Withdrawal",
      icon: (
        <Image
          width="40px"
          height="40px"
          src={PATH + `/assets/img/wallet/wallet_card/Withdraw.png`}
          alt={Constant.ALT_TAG_ICON_WITHDRAW}
        />
      ),
    },
    {
      label: t("home.promotion"),
      code: "Promotion",
      icon: (
        <Image
          width="40px"
          height="40px"
          src={PATH + `/assets/img/wallet/wallet_card/Promotion.png`}
          alt={Constant.ALT_TAG_ICON_PROMO}
        />
      ),
    },
    {
      label: t("home.vip.title"),
      code: "Vip",
      icon: <VipIcon width={"40px"} height={"40px"} color={"#C0C0C0"} />,
    },
  ];
  const ulStyle = { color: 'white' };

  const homePageSeo = [
    <>
      <Typography fontWeight={800} color="white"><h1>{i18n.t("home.seo.firsttitle")}</h1></Typography>
      <Typography mt={2} color="white">{t("home.seo.firstdesc")} </Typography>

      <Typography fontWeight={800} color="white"><h2>{i18n.t("home.seo.secondtitle")}</h2></Typography>
      <Typography mt={2} color="white">{t("home.seo.seconddesc")} </Typography>
      <ul style ={ulStyle}>
        <li>
          <Typography color="white">{t("home.seo.list1")}</Typography>
        </li>
        <Typography color="white">{t("home.seo.list2")}</Typography>
        <li>
          <Typography color="white">{t("home.seo.list3")}</Typography>
        </li>
        <Typography color="white">{t("home.seo.list4")}</Typography>
        <li>
          <Typography color="white">{t("home.seo.list5")}</Typography>
        </li>
        <Typography color="white">{t("home.seo.list6")}</Typography>
        <li>
          <Typography color="white">{t("home.seo.list7")}</Typography>
        </li>
        <Typography color="white">{t("home.seo.list8")}</Typography>
      </ul>
      <Typography fontWeight={800} color="white"><h3>{i18n.t("home.seo.thirdtitle")} </h3></Typography>
      <Typography mt={2} color="white">{t("home.seo.thirddesc")} </Typography>
    </>,
  ];

  const {
    data: gameCategory,
    isFetching: isGameCategoryFetching,
    isLoading: isGameCategoryLoading,
    isError: isGameCategoryError,
    isSuccess: isGameCategorySuccess,
    error: gameCategoryError,
  } = useGetGameCategoryQuery({ prefetch: true });

  const [
    getGameProvider,
    {
      data: gameProvider,
      isFetching: isGameProviderFetching,
      isLoading: isGameProviderLoading,
      isError: isGameProviderError,
      isSuccess: isGameProviderSuccess,
      error: gameProviderError,
    },
  ] = useLazyGetGameProviderQuery({ category: "" }, { prefetch: true });

  const {
    data: casinoProvider,
    isFetching: isCasinoProviderFetching,
    isLoading: isCasinoProviderLoading,
    isError: isCasinoProviderError,
    isSuccess: isCasinoProviderSuccess,
    error: casinoProviderError,
  } = useGetGameProviderQuery({ category: "LIVE_CASINO", isHome: true }, true, {
    prefetch: true,
  });

  const {
    data: allGameProvider,
    isFetching: isAllGameProviderFetching,
    isLoading: isAllGameProviderLoading,
    isError: isAllGameProviderError,
    isSuccess: isAllGameProviderSuccess,
    error: allGameProviderError,
  } = useGetGameProviderQuery({ category: "" }, { prefetch: true });

  const [
    getLaunchGame,
    {
      data: launchGame,
      isFetching: isLaunchGameFetching,
      isLoading: isLaunchGameLoading,
      isError: isLaunchGameError,
      isSuccess: isLaunchGameSuccess,
      error: launchGameError,
    },
  ] = useLazyGetLaunchGameQuery({ prefetch: true });

  const {
    data: homePage,
    isFetching: isHomePageFetching,
    isLoading: isHomePageLoading,
    isError: isHomePagetError,
    isSuccess: isHomePageSuccess,
    error: homePageError,
  } = useGetHomePageQuery({ prefetch: true });

  const {
    data: shareLink,
    isLoading: isShareLinkLoading,
    isError: isShareLinkError,
    isSuccess: isShareLinkSuccess,
    error: shareLinkError,
  } = useGetShareLinkQuery();

  useEffect(() => {
    if (!isLaunchGameLoading && !isLaunchGameFetching && isLaunchGameSuccess) {
      if (launchGame?.result?.gameType === "WEB") {
        window.open(launchGame.result.gameUrl, "_blank");
      }
    }
  }, [isLaunchGameLoading, isLaunchGameFetching, isLaunchGameSuccess]);

  const add = () => setNumber((number) => number + 1);

  useEffect(() => {
    setInterval(() => {
      add();
    }, 1000);
  }, []);

  useEffect(() => {
    if (mobileView && !selectedGameCategory) {
      setSelectedGameCategory("SLOT");
    }
  }, [mobileView]);

  useEffect(() => {
    getGameProvider({
      category: selectedGameCategory ? selectedGameCategory : undefined,
    });
  }, [selectedGameCategory, gameCategory, mobileView]);

  useEffect(() => {
    if (searchParams.get("refcode")) {
      localStorage.setItem("refCode", searchParams.get("refcode"));
      navigate(Constant.getPath("/register"));
    }
  }, []);

  const popup = process.env.REACT_APP_POPUP;

  const closePopup = () => setPopupOpen(false);
  const openPopup = () => setPopupOpen(true);

  const closeAffiliatePopup = () => setAffiliatePopupOpen(false);
  const openAffiliatePopup = () => setAffiliatePopupOpen(true);
  

  if (
    isHomePageFetching ||
    isHomePageLoading ||
    isAllGameProviderFetching ||
    isAllGameProviderLoading ||
    isGameCategoryFetching ||
    isGameCategoryLoading
  ) {
    return <Backdrop />;
  }

  if (isLaunchGameFetching || isLaunchGameLoading) {
    return <Backdrop />;
  }

  return (
    <Box pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
      {/* Announcement */}
      {homePage?.result?.announcements && (
        <Announcement announcementDetails={homePage?.result?.announcements} />
      )}
      {/* Banner */}
      <ImageCarousel
        banners={
          mobileView
            ? homePage?.result?.mobileBanners
            : homePage?.result?.banners
        }
        navigation={false}
        spaceBetween={0}
        alt={Constant.ALT_TAG_BANNER}
      />
      {/* Mobile view login & register (below banner) */}
      {mobileView && (
        <Box sx={{ display: "flex" }}>
          <Button
            variant="contained"
            sx={{
              fontWeight: "600",
              borderRadius: 0,
              background: `${theme.palette.secondary.main}`,
              width: "100%",
            }}
            disableRipple
            onClick={() =>
              isLoggedIn
                ? navigate(Constant.getPath("/profileWallet"), { state: { screen: "Deposit" } })
                : navigate(Constant.getPath("/register"))
            }
          >
            {isLoggedIn ? t("home.deposit") : t("home.signup")}
          </Button>
          <Button
            variant="contained"
            sx={{
              fontWeight: "600",
              width: "100%",
              borderRadius: 0,
              background: theme.palette.primary.main,
            }}
            disableRipple
            onClick={() =>
              isLoggedIn
                ? navigate(Constant.getPath("/profileWallet"), {
                    state: { screen: "Withdrawal" },
                  })
                : navigate(Constant.getPath("/login"))
            }
          >
            {isLoggedIn ? t("home.withdraw") : t("home.login")}
          </Button>
        </Box>
      )}
      {/*Mobile View Wallet Card */}
      {mobileView && isLoggedIn && (
        // <Box
        //   sx={{
        //     background: `url('${homePage?.mobileBackground[0]?.thumbnailUrl}')`,
        //   }}
        // >
        //   <Container>
        //     <Box py={2}>
        <Box>
          <WalletCard screens={walletCardScreens} />
        </Box>
        //   </Container>
        // </Box>
      )}
      {/* Mobile view Game Category & Header Bar */}
      {mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.mobileBackground[0]?.thumbnailUrl}')`,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            pb={2}
          >
            <Box
              p={1}
              display="flex"
              sx={{
                background: theme.palette.primary.main,
              }}
            >
              <Grid container spacing={2}>
                {gameCategory?.result.map((item, index) => (
                  <Grid key={index} item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      position="relative"
                      sx={{
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      onClick={() => setSelectedGameCategory(item.code)}
                      alignItems="center"
                    >
                      <>
                        {item.code === "SLOT" ? (
                          // <SlotIcon
                          //   width={40}
                          //   height={40}
                          //   color={
                          //     item.code === selectedGameCategory
                          //       ? theme.palette.secondary.main
                          //       : "white"
                          //   }
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO + "/SLOT.png"
                            }
                            alt={Constant.ALT_TAG_ICON_SLOT}
                          />
                        ) : item.code === "LIVE_CASINO" ? (
                          // <CasinoIcon
                          //   width={40}
                          //   height={40}
                          //   color={
                          //     item.code === selectedGameCategory
                          //       ? theme.palette.secondary.main
                          //       : "white"
                          //   }
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO +
                              "/LIVE_CASINO.png"
                            }
                            alt={Constant.ALT_TAG_ICON_CASINO}
                          />
                        ) : item.code === "SPORTBOOK" ? (
                          // <SportIcon
                          //   width={40}
                          //   height={40}
                          //   color={
                          //     item.code === selectedGameCategory
                          //       ? theme.palette.secondary.main
                          //       : "white"
                          //   }
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO + "/SPORT.png"
                            }
                            alt={Constant.ALT_TAG_ICON_SPORT}
                          />
                        ) : item.code === "FISH_HUNTER" ? (
                          // <FishingIcon
                          //   width={40}
                          //   height={40}
                          //   color={
                          //     item.code === selectedGameCategory
                          //       ? theme.palette.secondary.main
                          //       : "white"
                          //   }
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO + "/FISHING.png"
                            }
                            alt={Constant.ALT_TAG_ICON_FISHING}
                          />
                        ) : item.code === "SABUNG" ? (
                          // <SabungIcon
                          //   width={40}
                          //   height={40}
                          //   color={
                          //     item.code === selectedGameCategory
                          //       ? theme.palette.secondary.main
                          //       : "white"
                          //   }
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO + "/SABUNG.png"
                            }
                            alt={Constant.ALT_TAG_ICON_SABUNG}
                          />
                        ) : null}
                        {item.code === selectedGameCategory && (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: -5,
                              left: "50%",
                              transform: "translateX(-50%)",
                              borderBottom: "2px solid",
                              width: "20%",
                              borderColor: theme.palette.secondary.main,
                            }}
                          />
                        )}
                        <Typography
                          color={
                            item.code === selectedGameCategory
                              ? theme.palette.secondary.main
                              : "white"
                          }
                          textAlign="center"
                          fontSize={14}
                          fontWeight={600}
                        >
                          {item?.label}
                        </Typography>
                      </>
                    </Box>
                  </Grid>
                ))}
                {!isLoggedIn &&
                  additionalGameBarItems.map((item, index) => (
                    <Grid key={index} item xs={3}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        position="relative"
                        sx={{
                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                        onClick={() => navigate(Constant.getPath("/" + item.code?.toLowerCase()))}
                        alignItems="center"
                      >
                        {item?.label === t("home.promotion") ? (
                          // <PromotionIcon
                          //   width={40}
                          //   height={40}
                          //   color={"white"}
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO +
                              "/PROMOTION.png"
                            }
                            alt={Constant.ALT_TAG_ICON_PROMO}
                          />
                        ) : item?.label === t("home.vip.title") ? (
                          // <VipIcon width={40} height={40} color={"white"} />
                          <Image
                            width={40}
                            height={40}
                            src={process.env.REACT_APP_HEADER_LOGO + "/VIP.png"}
                            alt={Constant.ALT_TAG_ICON_VIP}
                          />
                        ) : null}
                        <Typography
                          color={"white"}
                          textAlign="center"
                          fontSize={14}
                          fontWeight={600}
                        >
                          {item?.label}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Box>
            <Box mt={1} p={1} display="flex">
              {!isGameProviderFetching &&
                !isGameProviderLoading &&
                isGameProviderSuccess && (
                  <>
                    <Grid
                      container
                      sx={{
                        border: 1,
                        borderColor: theme.palette.primary.main,
                      }}
                      py={2}
                    >
                      {gameProvider?.result?.map((provider, idx) => (
                        <Grid
                          key={idx}
                          item
                          xs={3}
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Box
                            onClick={() => {
                              let hasGameList = gameCategory?.result?.find(
                                (category) =>
                                  category.code === selectedGameCategory
                              )?.hasGameList;
                              if (hasGameList) {
                                navigate(
                                  Constant.getPath(`/game/${selectedGameCategory?.toLowerCase().replace("_", "-")}/${provider.name.toLowerCase().replace("_", "-")}`),
                                  {
                                    state: {
                                      providerId: provider.id,
                                    },
                                  }
                                );
                              } else {
                                if (!isLoggedIn) {
                                  dispatch(
                                    openModal({
                                      title: t("dialog.proceedtologin"),
                                      button: [
                                        {
                                          label: t("dialog.login"),
                                          onSubmit: () => {
                                            dispatch(closeModal());
                                            navigate(Constant.getPath("/login"));
                                          },
                                        },
                                      ],
                                    })
                                  );
                                } else {
                                  getLaunchGame({
                                    id: provider?.id,
                                    type: provider?.gameType,
                                    category: selectedGameCategory,
                                  });
                                }
                              }
                            }}
                          >
                            <Box display="flex" flexDirection="column">
                              <Image src={provider.thumbnailUrl} alt={Constant.ALT_TAG_GENERAL_PROVIDER + provider.name}/>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
            </Box>
          </Box>
        </Box>
      )}
      {/* Description picture under banner */}
      {!mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[0]?.thumbnailUrl}')`,
          }}
        >
          <Image src={homePage?.result?.common?.web_promote_banner} alt={Constant.ALT_TAG_STEPS}/>
        </Box>
      )}
      {/* Progressive jackpot */}
      {!mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[1]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box
              sx={{
                position: "relative",
                zIndex: 1,
              }}
              pt={5}
            >
              <Image src={homePage?.result?.common?.jackpot} alt={Constant.ALT_TAG_JACKPOT} />
              <Box
                sx={{
                  position: "absolute",
                  top: tabletView ? "68%" : "67%",
                  left: "53%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <FlipNumbers
                  height={70}
                  width={tabletView ? 57 : 84}
                  color={theme.palette.secondary.main}
                  background="transparent"
                  play
                  numberStyle={{
                    fontSize: tabletView ? 30 : 40,
                    fontWeight: "bold",
                  }}
                  nonNumberStyle={{
                    fontSize: 40,
                    color: theme.palette.secondary.main,
                  }}
                  className="flip-numbers"
                  numbers={`${number}`}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {/* New Live Casino */}
      {!mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[2]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box>
              <Typography
                pb={5}
                textAlign="center"
                variant="h5"
                fontWeight="800"
                color={"white"}
              >
                {t("home.livecasino")}
              </Typography>
              <LiveCasinoCarousel
                data={{
                  result: casinoProvider?.result,
                  allGameProvider: allGameProvider?.result,
                  gameCategory: gameCategory?.result,
                }}
                slidesPerView={!tabletView ? 5 : 4}
                pagination={false}
                navigation={false}
                spaceBetween={20}
              />
            </Box>
          </Container>
        </Box>
      )}
      {/* New Hot Games */}
      {!mobileView && homePage?.result?.featureGames && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[3]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Typography
              pt={8}
              textAlign="center"
              variant="h5"
              fontWeight="800"
              color="white"
            >
              {t("home.hotgames")}
            </Typography>

            <Box
              pt={4}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {!tabletView && (
                <Box
                  sx={{
                    display: "flex",
                    alignSelf: "flex-start",
                    textAlign: "center",
                    maxWidth: "50%",
                    mr: 2,
                    borderRadius: 4
                  }}
                >
                  <img src={process.env.REACT_APP_HOT_GAMES_PIC} alt={Constant.ALT_TAG_HOME_HOT_GAME}/>
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                maxWidth={!tabletView ? "80%" : "100%"}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  onChange={(event, val) => setGameShownValue(val)}
                  sx={{
                    borderBottom: `1px solid white`,
                  }}
                >
                  <Tab
                    label={"RECOMMEND GAMES"}
                    sx={{
                      color: "white",
                      backgroundColor:
                        gameShownValue === 0 && theme.palette.primary.main,
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      fontWeight: 600,
                    }}
                    disableRipple
                  />
                  <Tab
                    label={"HOT GAMES"}
                    sx={{
                      color: "white",
                      backgroundColor:
                        gameShownValue === 1 && theme.palette.primary.main,
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      fontWeight: 600,
                    }}
                    disableRipple
                  />
                </Tabs>
                <TabPanel value={gameShownValue} index={0}>
                  <CardCarousel
                    imageWidth={tabletView ? "122px" : "131px"}
                    imageHeight={tabletView ? "122px" : "131px"}
                    data={{
                      result: homePage?.result?.recommendGame,
                      allGameProvider: allGameProvider?.result,
                      gameCategory: gameCategory?.result,
                    }}
                    slidesPerView={5}
                    pagination={false}
                    navigation={false}
                    spaceBetween={0}
                    rows={2}
                  />
                </TabPanel>
                <TabPanel value={gameShownValue} index={1}>
                  <CardCarousel
                    imageWidth={tabletView ? "122px" : "131px"}
                    imageHeight={tabletView ? "122px" : "131px"}
                    data={{
                      result: homePage?.result?.featureGames,
                      allGameProvider: allGameProvider?.result,
                      gameCategory: gameCategory?.result,
                    }}
                    slidesPerView={5}
                    pagination={false}
                    navigation={false}
                    spaceBetween={0}
                    rows={2}
                  />
                </TabPanel>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {/* Promotion & Affiliate */}
      {homePage?.result?.bonuses && homePage?.result?.common?.affiliate && (
        <Box
          sx={{
            background: mobileView
              ? `url('${homePage?.mobileBackground[1]?.thumbnailUrl}')`
              : `url('${homePage?.webBackground[5]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={1} pb={5}>
              <Grid container spacing={0}>
                <Grid item xs={!mobileView ? 6 : 12}>
                  {!mobileView && (
                    <Typography
                      mb={3}
                      textAlign="center"
                      variant="h5"
                      fontWeight="800"
                      color={"white"}
                    >
                      {t("home.promotion")}
                    </Typography>
                  )}
                  {mobileView ? (
                    <Grid
                      container
                      spacing={0}
                      sx={{ justifyContent: "space-evenly" }}
                    >
                      <Grid item>
                        <img
                          style={{ borderRadius: 4 }}
                          height="100px"
                          width="100px"
                          onClick={() => navigate(Constant.getPath("/promotion"))}
                          src={process.env.REACT_APP_HOME_PROMOTION}
                          alt={Constant.ALT_TAG_ICON_PROMO}
                        />
                      </Grid>
                      <Grid item>
                        <img
                          height="100px"
                          width="100px"
                          style={{ borderRadius: 4 }}
                          onClick={() => navigate(Constant.getPath("/affiliate"))}
                          src={process.env.REACT_APP_HOME_AFFILIATE}
                          alt={Constant.ALT_TAG_ICON_AFFL}
                        />
                      </Grid>
                      <Grid item>
                        <img
                          height="100px"
                          width="100px"
                          style={{ borderRadius: 4 }}
                          onClick={() => navigate(Constant.getPath("/vip"))}
                          src={process.env.REACT_APP_HOME_VIP}
                          alt={Constant.ALT_TAG_ICON_VIP}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <ImageCarousel
                      banners={homePage?.result?.bonuses}
                      slidesPerView={1}
                      pagination={true}
                      imageStyle={{ borderRadius: 3 }}
                      onClick={() => navigate(Constant.getPath("/promotion"))}
                    />
                  )}
                </Grid>
                <Grid item xs={!mobileView ? 6 : 12}>
                  <Typography
                    mb={3}
                    textAlign="center"
                    variant="h5"
                    fontWeight="800"
                    color={"white"}
                  >
                    {t("home.affiliate")}
                  </Typography>
                  <Image
                    sx={{ borderRadius: 3, cursor: "pointer" }}
                    src={homePage?.result?.common?.affiliate}
                    alt={Constant.ALT_TAG_ADS_AFFL}
                    // onClick={() => navigate("/affiliate")}
                    onClick={() => {  
                                     dispatch(
                                       openModal({
                                        title: t("home.affiliateContent"),
                                         content: (
                                                          <>
                                                            <div
                                                            style={{paddingBottom: "30px"}}
                                                              dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(
                                                                  homePage?.result?.affiliate_content
                                                                ),
                                                              }}
                                                            ></div>
                                                            {isLoggedIn && (
                                                              <div style={{ display: 'flex', justifyContent: 'center',
                                                                position: "absolute", bottom: 0, width: "100%", padding: "20px",
                                                                background: theme.palette.background.paper, left: 0 }}>
                                                                <TextField
                                                                  label={t("walletcard.myaccountpage.referrallink")}
                                                                  value={shareLink?.result?.url}
                                                                  InputLabelProps={{ shrink: true }}
                                                                  disabled
                                                                  type="text"
                                                                  size="small"
                                                                  sx={{ width: "400px" }}
                                                                  InputProps={{
                                                                    endAdornment: (
                                                                      <InputAdornment position="end">
                                                                        <IconButton
                                                                          disableRipple
                                                                          sx={{
                                                                            "&.MuiButtonBase-root:hover": {
                                                                              backgroundColor: "transparent",
                                                                            },
                                                                          }}
                                                                        >
                                                                          <CopiedTooltip text={shareLink?.result?.url} />
                                                                        </IconButton>
                                                                      </InputAdornment>
                                                                    ),
                                                                  }}
                                                                />
                                                              </div>
                                                            )}
                                                          </>
                                                        ),
                                         button: !isLoggedIn
                                           ? [
                                                {
                                                  label: t("promotion.login"),
                                                  onSubmit: () => {
                                                  dispatch(closeModal());
                                                    if (mobileView) {
                                                       navigate(Constant.getPath("/login"));
                                                    } else {
                                                      dispatch(
                                                    openModal({
                                                       title: t("dialog.proceedtologin"),
                                                           button: [
                                                                       {
                                                                         label: t("dialog.login"),
                                                                         onSubmit: () =>
                                                                          dispatch(closeModal()),
                                                                       },
                                                                  ],
                                                                })
                                                               );
                                                             }
                                                           },
                                                         },
                                                         {
                                                             label: t("promotion.register"),
                                                             onSubmit: () => {
                                                             dispatch(closeModal());
                                                             navigate(Constant.getPath("/register"));
                                                          },
                                                        },
                                             ]
                                           : [],
                                       })
                                     );
                                   }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
      {/* Game Provider for web view */}
      {/* {!mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.mobileBackground[0]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={6} display="flex" flexDirection="column">
              <Grid
                container
                display="flex"
                justifyContent="center"
                sx={{
                  background: theme.palette.primary.main,
                }}
                // p={2}
              >
                <Grid item xs={2}>
                  <Box
                    sx={{
                      backgroundColor: darken(theme.palette.primary.main, 0.2),
                      height: "40px",
                      borderRadius: {
                        borderBottomLeftRadius: 40,
                        borderBottomRightRadius: 40,
                      },
                      mx: 1,
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      position="relative"
                      sx={{
                        "&:hover": {
                          background: "transparent",
                        },
                        cursor: "pointer",
                        position: "relative",
                        top: "10%",
                      }}
                      onClick={() => setSelectedGameCategory("")}
                      alignItems="center"
                    >
                      {selectedGameCategory === "" && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -5,
                            left: "50%",
                            transform: "translateX(-50%)",
                            borderBottom: "2px solid",
                            width: "20%",
                            borderColor: theme.palette.secondary.main,
                          }}
                        />
                      )}
                      <Typography
                        color={
                          selectedGameCategory === ""
                            ? theme.palette.secondary.main
                            : "white"
                        }
                        textAlign="center"
                        fontWeight={600}
                      >
                        {t("home.allgames")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {gameCategory?.result.map((item, index) => (
                  <Grid item key={index} xs={2}>
                    <Box
                      sx={{
                        backgroundColor: darken(
                          theme.palette.primary.main,
                          0.2
                        ),
                        height: "40px",
                        borderRadius: {
                          borderBottomLeftRadius: 40,
                          borderBottomRightRadius: 40,
                        },
                        mx: 1,
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        position="relative"
                        sx={{
                          "&:hover": {
                            background: "transparent",
                          },
                          position: "relative",
                          top: "10%",
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedGameCategory(item.code)}
                        alignItems="center"
                      >
                        <>
                          {item.code === selectedGameCategory && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: -5,
                                left: "50%",
                                transform: "translateX(-50%)",
                                borderBottom: "2px solid",
                                width: "20%",
                                borderColor: theme.palette.secondary.main,
                              }}
                            />
                          )}
                          <Typography
                            color={
                              item.code === selectedGameCategory
                                ? theme.palette.secondary.main
                                : "white"
                            }
                            textAlign="center"
                            fontWeight={600}
                          >
                            {item?.label}
                          </Typography>
                        </>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box border={1} p={2} borderColor={theme.palette.primary.main}>
                <Grid container>
                  {!isGameProviderFetching &&
                    !isGameCategoryLoading &&
                    isGameProviderSuccess && (
                      <>
                        <Grid item sm={5} md={4}>
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              let hasGameList = gameCategory?.result?.find(
                                (category) =>
                                  category.code === selectedGameCategory
                              )?.hasGameList;
                              if (hasGameList) {
                                navigate(
                                  `/game/${selectedGameCategory.toLowerCase()}`,
                                  {
                                    state: {
                                      providerId: gameProvider?.result[0]?.id,
                                    },
                                  }
                                );
                              } else {
                                if (!isLoggedIn) {
                                  dispatch(
                                    openModal({
                                      title: t("dialog.proceedtologin"),
                                      button: [
                                        {
                                          label: t("dialog.login"),
                                          onSubmit: () =>
                                            dispatch(closeModal()),
                                        },
                                      ],
                                    })
                                  );
                                } else {
                                  getLaunchGame({
                                    id: gameProvider?.result[0]?.id,
                                    type: gameProvider?.result[0]?.gameType,
                                    category: selectedGameCategory,
                                  });
                                }
                              }
                            }}
                          >
                            <Image
                              width="275px"
                              height="275px"
                              src={gameProvider?.result[0]?.thumbnailUrl}
                            />
                          </Box>
                        </Grid>
                        <Grid item sm={7} md={8} container>
                          <Grid container>
                            {gameProvider?.result?.map((provider, idx) => (
                              <>
                                {idx !== 0 && idx <= 6 && (
                                  <Grid item sm={4} md={2}>
                                    <Box
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        let hasGameList =
                                          gameCategory?.result?.find(
                                            (category) =>
                                              category.code ===
                                              selectedGameCategory
                                          )?.hasGameList;
                                        if (hasGameList) {
                                          navigate(
                                            `/game/${selectedGameCategory.toLowerCase()}`,
                                            {
                                              state: {
                                                providerId: provider.id,
                                              },
                                            }
                                          );
                                        } else {
                                          if (!isLoggedIn) {
                                            dispatch(
                                              openModal({
                                                title: t(
                                                  "dialog.proceedtologin"
                                                ),
                                                button: [
                                                  {
                                                    label: t("dialog.login"),
                                                    onSubmit: () =>
                                                      dispatch(closeModal()),
                                                  },
                                                ],
                                              })
                                            );
                                          } else {
                                            getLaunchGame({
                                              id: provider?.id,
                                              type: provider?.gameType,
                                              category: selectedGameCategory,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <Image
                                        width="110px"
                                        height="110px"
                                        src={provider.thumbnailUrl}
                                      />
                                    </Box>
                                  </Grid>
                                )}
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      </>
                    )}
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      )} */}
      {/* Live Casino */}
      {/* {!mobileView && homePage?.result?.liveCasino && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[2]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={{ xs: 4, sm: 4, md: 4 }} pb={{ xs: 11, sm: 5, md: 5 }}>
              <Typography
                pb={3}
                textAlign="center"
                variant="h5"
                fontWeight="800"
                color={"white"}
              >
                {t("home.livecasino")}
              </Typography>
              <ImageCarousel
                banners={homePage?.result?.liveCasino.slice(1)}
                slidesPerView={3}
                spaceBetween={0}
                navigation={false}
                autoHeight={true}
                loop={true}
                effect="coverflow"
                pagination={false}
                onClick={() => navigate("/game/live_casino")}
              />
            </Box>
          </Container>
        </Box>
      )} */}
      {/* Feature */}
      {/* {!mobileView && homePage?.result?.common?.web_wallet_step && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[4]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={4}>
              <Box
                sx={{
                  p: 1,
                  background: `url(${process.env.REACT_APP_WALLET_STEP_BG})`,
                }}
              >
                <Typography
                  pb={3}
                  textAlign="center"
                  variant="h5"
                  fontWeight="800"
                >
                  {t("home.feature.title")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_1} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.optimizedtitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.optimizeddesc")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      pt={1}
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_2} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.speedytitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.speedydesc")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_3} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.certifiedtitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.certifieddesc")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_4} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.safeguardedtitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.safeguardeddesc")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      )} */}
      {/* Mobile view feature banner */}
      {mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.mobileBackground[2]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pb={11}>
              <Image src={homePage?.result?.walletSteps} alt={Constant.ALT_TAG_STEPS} />
            </Box>
          </Container>
        </Box>
      )}
      {/* Misc section */}
      {/* {mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.mobileBackground[3]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={2} pb={11}>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/about")}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.aboutus")}
              </Typography>
              <Divider />
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/vip")}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.vip")}
              </Typography>
              <Divider />
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => null}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.affiliate")}
              </Typography>
              <Divider />
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => null}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.livechat")}
              </Typography>
              <Divider />
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/terms")}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.terms")}
              </Typography>
              <Divider />
            </Box>
          </Container>
        </Box>
      )} */}
      {/* SEO text */}
      {!mobileView && (lang == "en" || !lang) && (
          <Box
        sx={{
          background: mobileView
            ? `url('${homePage?.mobileBackground[3]?.thumbnailUrl}')`
            : `url('${homePage?.webBackground[6]?.thumbnailUrl}')`,
        }}
      >
        <Container>
          <Box pt={{ xs: 4, sm: 4, md: 4 }} pb={{ xs: 11, sm: 5, md: 5 }}>
            <TextCarousel
              html={homePageSeo}
              slidesPerView={1}
              pagination={true}
              spaceBetween={0}
            />
          </Box>
        </Container>
      </Box>
      )}
      {/* Accordion of terms */}
      {!mobileView && (lang == "en" || !lang) && (
     <Box
        sx={{
          background: mobileView
            ? `url('${homePage?.mobileBackground[3]?.thumbnailUrl}')`
            : `url('${homePage?.webBackground[6]?.thumbnailUrl}')`,
        }}
      >
        <Container>
          <Box pt={{ xs: 4, sm: 4, md: 4 }} pb={{ xs: 11, sm: 5, md: 5 }}>
            <AccordionComponent title={t("home.t&c.firsttitle")} >
              {t("home.t&c.firstdesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.secondtitle")}>
              {t("home.t&c.seconddesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.thirdtitle")}>
              {t("home.t&c.thirddesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.fourthtitle")}>
              {t("home.t&c.fourthdesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.fifthtitle")}>
              {t("home.t&c.fifthdesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.sixthtitle")}>
              {t("home.t&c.sixththdesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.seventhtitle")}>
              {t("home.t&c.sevenththdesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.eighthtitle")}>
              {t("home.t&c.eighththdesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.ninethtitle")}>
              {t("home.t&c.nineththdesc")}
              <ul>
                <li>{t("home.t&c.list1")}</li>
                <li>{t("home.t&c.list2")}</li>
                <li>{t("home.t&c.list3")}</li>
                <li>{t("home.t&c.list4")}</li>
                <li>{t("home.t&c.list5")}</li>
                <li>{t("home.t&c.list6")}</li>
              </ul>
            </AccordionComponent>
          </Box>
        </Container>
      </Box>
  )}
      {/* Default pop up */}
      {<Modal open={popupOpen} onClose={closePopup}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: mobileView ? 350 : "35%",
            transform: "translate(-50%, -50%)",
            outline: "none",
          }}
          textAlign="right"
        >
          <Image src={popup} sx={{ borderRadius: "5%" }} alt={Constant.ALT_TAG_HOME_ADS}/>
        </Box>
      </Modal> }
      <Modal open={affiliatePopupOpen} onClose={closeAffiliatePopup}>
        <Box
          sx={{
            minWidth: "350px",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: mobileView ? 350 : null,
            transform: "translate(-50%, -50%)",
            outline: "none",
            color: "white",
            padding: "15px 30px",
            textAlign: "center",
            background: theme.palette.background.paper
          }}
          textAlign="right"
        >
          <IconButton sx={{ position: "absolute", top: 0, right: 0 }} edge="start" onClick={closeAffiliatePopup}>
            <CloseIcon />
          </IconButton>
          <div dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                homePage?.result?.common?.affiliate_content
              ),
            }}
          ></div>

          {!isLoggedIn &&
            <Box sx={{marginTop: "15px"}}>
              <Button
                variant="contained"
                sx={{
                  mr: 1,
                }}
                onClick={() => mobileView ? navigate(Constant.getPath("/login")) : closeAffiliatePopup()}
              >
                {t("header.login")}
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate(Constant.getPath("/register"))}
              >
                {t("header.signup")}
              </Button>
            </Box>
          }

          {isLoggedIn && <p>
            <TextField
              label={t("walletcard.myaccountpage.referrallink")}
              value={shareLink?.result?.url}
              InputLabelProps={{ shrink: true }}
              disabled
              type="text"
              size="small"
              sx={{width: "300px"}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <CopiedTooltip text={shareLink?.result?.url} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </p>}
        </Box>
      </Modal>
    </Box>
  );
};

export default Home;
