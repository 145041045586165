import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  FormControl,
  Box,
  Stack,
  Pagination,
  useTheme,
  darken,
} from "@mui/material";
import DateRangePicker from "../form/DateRangePicker";
import format from "date-fns/format";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { lighten } from "@material-ui/core";

const BetRecord = ({ useListBetHistoryMutation }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [
    betHistory,
    result,
    // { data, isLoading, isError, isSuccess, error },
  ] = useListBetHistoryMutation();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [offset, setOffset] = useState(0);

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handlePageChange = (e, p) => {
    setOffset(p - 1);
  };

  useEffect(() => {
    if (!(endDate < startDate)) {
      let data = {
        criteria: {
          dateFrom: format(startDate, "dd-MM-yyyy"),
          dateTo: format(endDate, "dd-MM-yyyy"),
        },
        offset: offset,
      };
      betHistory(data).unwrap();
    }
  }, [startDate, endDate]);

  return (
    <Container>
      <Typography fontWeight="bold" color="white">
        {t("walletcard.betrecord")}
      </Typography>
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "30px",
        }}
      >
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          size="small"
        />
      </FormControl>
      <Box sx={{ height: 400, width: "100%", mt: "30px" }}>
        <DataGrid
          sx={{
            "& .MuiDialogContent-root": {
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "12px",
              },
              "::-webkit-scrollbar-track": {
                background: theme.palette.text.disabled,
              },
              "::-webkit-scrollbar-thumb": {
                background: theme.palette.background.paper,
              },
            },
            color: "white",
          }}
          rows={
            result?.data?.result?.data.map((content, idx) => {
              return {
                ...content,
                id: idx + 1,
              };
            }) || []
          }
          columns={[
            {
              field: "providerName",
              headerName: t("walletcard.betrecordpage.providername"),
              width: 150,
              editable: false,
            },
            {
              field: "totalBetCount",
              headerName: t("walletcard.betrecordpage.betcount"),
              width: 120,
              editable: false,
            },
            {
              field: "totalBetAmount",
              headerName: t("walletcard.betrecordpage.totalbetamount"),
              width: 150,
              editable: false,
            },
            {
              field: "winLoseAmount",
              headerName: t("walletcard.betrecordpage.winlostamount"),
              width: 150,
              editable: false,
            },
            {
              field: "turnoverAmount",
              headerName: t("walletcard.betrecordpage.turnoverAmount"),
              width: 150,
              editable: false,
            },
          ]}
          hideFooter={true}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          // disableSelectionOnClick
          // isCellEditable={false}
          onPageChange={(newPage) => setOffset(newPage)}
        />
      </Box>
      <Stack mt={2} spacing={2} size="small" alignItems="center">
        <Pagination
          // disabled={selectedAnnouncement.selected ? true : false}
          // onChange={handlePageChange}
          onChange={handlePageChange}
          count={result?.data?.result?.totalPages}
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              color: "white",
              "&.Mui-selected": {
                background: lighten(theme.palette.background.paper, 0.02),
                color: "white",
              },
            },
          }}
        />
      </Stack>
    </Container>
  );
};

export default BetRecord;
