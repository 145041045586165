import {
  Backdrop as MuiBackdrop,
  CircularProgress,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";

const Backdrop = ({ fullOpacity }) => {
  const theme = useTheme();

  const bgColor = fullOpacity
    ? theme.palette.background.paper
    : theme.palette.background.paper + "AA";

  return (
    <MuiBackdrop
      sx={{
        color: "white",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: bgColor,
      }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  );
};

Backdrop.propTypes = {
  fullOpacity: PropTypes.bool,
};

export default Backdrop;
