import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  Grid,
  useMediaQuery,
  Tabs,
  Tab,
  darken,
} from "@mui/material";
import { CopyAll as CopyAllIcon } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextField from "../form/FormTextField";
import FormDatePicker from "../form/FormDatePicker";
import FormFileInput from "../form/FormFileInput";
import { setAlert } from "../../features/slices/alertSlice";
import Image from "../common/Image";
import { useTranslation } from "react-i18next";
import CopiedTooltip from "../common/CopiedTooltip";
import {useNavigate} from "react-router-dom";
import * as Constant from "../../features/constant";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Deposit = ({ depositDetails, useDepositMutation }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabValue, setTabValue] = useState(0);
  const [selectedQuickpay, setSelectedQuickpay] = useState(
    depositDetails?.paymentGateways[
      Object?.keys(depositDetails?.paymentGateways)[0]
    ]?.paymentGateway
  );
  const [allQuickpayBank, setAllQuickpayBank] = useState(
    depositDetails?.paymentGateways[
      Object?.keys(depositDetails?.paymentGateways)[0]
    ]?.banks || []
  );
  const [selectedQuickpayBank, setSelectedQuickpayBank] = useState(
    depositDetails?.paymentGateways[
      Object?.keys(depositDetails?.paymentGateways)[0]
    ]?.banks[0]
  );
  const { t } = useTranslation();


  const depositType = [];
  if (depositDetails && depositDetails.hasOwnProperty("paymentGateways") && Object.keys(depositDetails.paymentGateways).length > 0) {
    depositType.push({ label: t("walletcard.depositpage.quickpay.title") });
    depositType.push({ label: t("walletcard.depositpage.title") });
  } else {
    depositType.push({ label: t("walletcard.depositpage.title") });
  }

  const depositSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t("validation.amountonly"))
      .min(100, t("validation.minamount"))
      .required(t("validation.amountisrequired")),
    receipt: yup.mixed().test("file", t("validation.receipt"), (value) => {
      if (value?.length > 0) {
        return true;
      }
      return false;
    }),
    // ignore for now
    // promotion: yup.string().required("Please select a promotion."),
  });

  const quickpaySchema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t("validation.amountonly"))
      .min(100, t("validation.minamount"))
      .required(t("validation.amountisrequired")),
  });

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(depositSchema),
  });

  const {
    control: qpControl,
    handleSubmit: handleQpSubmit,
    setValue: setQpValue,
    reset: resetQp,
  } = useForm({
    resolver: yupResolver(quickpaySchema),
  });

  const [selectedBank, setSelectedBank] = useState(depositDetails?.banks[0]);

  const [deposit, { data, isLoading, isError, isSuccess, error }] =
    useDepositMutation();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const submitDeposit = async (data) => {
    const formData = new FormData();
    const date = new Date(data.date).toISOString().split("T");
    formData.append("amount", data.amount);
    formData.append("receiptDateTime", date[0] + ";" + date[1]);
    formData.append("receipt", data.receipt[0]);
    formData.append(
      "bankCode",
      selectedBank?.bankCode || depositDetails?.banks[0].bankCode
    );
    await deposit({ deposit: formData, paymentGateway: "" }).unwrap();
    navigate(Constant.getPath('/'));
  };

  const submitQuickpay = async (data) => {
    const formData = new FormData();
    formData.append("bankCode", selectedQuickpayBank?.bankCode);
    formData.append("amount", data?.amount);
    const res = await deposit({
      deposit: formData,
      paymentGateway: selectedQuickpay,
    }).unwrap();

    if (res) {
      window.open(res.result.paymentUrl, "_blank");
    }
    resetQp();
    navigate(Constant.getPath('/'));
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.depositpage.successmessage"),
          variant: "success",
        })
      );
      reset();
    }
    if (!isLoading && isError) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error?.data?.message,
          variant: "error",
        })
      );
      reset();
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <Box px={1}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        onChange={handleTabChange}
      >
        {depositType.map((type, index) => (
          <Tab
            key={index}
            label={type.label}
            sx={{
              color: theme.palette.primary.main,
              borderBottom:
                index === tabValue && `2px solid ${theme.palette.primary.main}`,
              fontWeight: 600,
            }}
            disableRipple
          />
        ))}
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <Typography fontWeight="bold" color="white">
              {t("walletcard.depositpage.quickpay.method")}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {Object.keys(depositDetails?.paymentGateways)?.map((method) => (
              <Grid item xs={6} sm={3}>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    borderColor:
                      selectedQuickpay ===
                      depositDetails?.paymentGateways[method]?.paymentGateway
                        ? theme.palette.primary.main
                        : darken(theme.palette.background.paper, 0.2),
                    display: "flex",
                    height: 50,
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    p: 1,
                    backgroundColor: darken(
                      theme.palette.background.paper,
                      0.1
                    ),
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedQuickpay(
                      depositDetails?.paymentGateways[method]?.paymentGateway
                    );
                    setAllQuickpayBank(
                      depositDetails?.paymentGateways[method]?.banks
                    );
                    setSelectedQuickpayBank(
                      depositDetails?.paymentGateways[method]?.banks[0]
                    );
                  }}
                >
                  <Image
                    width="40px"
                    height="40px"
                    src={
                      depositDetails?.paymentGateways[method]
                        ?.paymentGatewayIcon
                    }
                  />
                  <Typography ml={mobileView && 1} color="white">
                    {depositDetails?.paymentGateways[method]?.paymentGateway}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box my={2}>
            <Typography fontWeight="bold" color="white">
              {t("walletcard.depositpage.quickpay.bank")}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {allQuickpayBank?.map((quickpayBank) => (
              <Grid item xs={6} sm={3}>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    borderColor:
                      selectedQuickpayBank === quickpayBank
                        ? theme.palette.primary.main
                        : darken(theme.palette.background.paper, 0.2),
                    display: "flex",
                    height: 50,
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    p: 1,
                    backgroundColor: darken(
                      theme.palette.background.paper,
                      0.1
                    ),
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedQuickpayBank(quickpayBank)}
                >
                  <Image
                    width="40px"
                    height="40px"
                    src={quickpayBank?.iconUrl}
                  />
                  <Typography ml={mobileView && 1} color="white">
                    {quickpayBank?.bankName}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box mt={4}>
            <Typography color="white">
              {t("walletcard.depositpage.quickpay.depositamount")}
            </Typography>
            <form onSubmit={handleQpSubmit(submitQuickpay)}>
              <FormTextField
                label={`${t("walletcard.depositpage.quickpay.amount")} (${
                  process.env.REACT_APP_CURRENCY_CODE
                })`}
                control={qpControl}
                controllerName="amount"
                defaultValue={""}
                placeholder={t("placeholder.minamount")}
                size="small"
              />
              <Grid container py={2}>
                {depositDetails?.defaultAmountSelection?.map((item, index) => (
                  <Grid item>
                    <Button
                      key={index}
                      disableRipple
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        setQpValue("amount", item.content, {
                          shouldValidate: true,
                        });
                      }}
                    >
                      <Box
                        display="flex"
                        sx={{
                          border: 1,
                          width: "58px",
                          height: "40px",
                          borderColor: "white",
                          boxShadow: 3,
                        }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            color: "white",
                          }}
                        >
                          {item.content}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <Box display="flex" mt={2}>
                <Button color="success" variant="contained" type="submit">
                  {t("walletcard.depositpage.quickpay.deposit")}
                </Button>
                <Box ml={2}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => resetQp()}
                  >
                    {t("walletcard.depositpage.quickpay.clear")}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box pl="10px" mb={2}>
          <Typography fontWeight="bold" color="white">
            {t("walletcard.deposit")}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Grid container spacing={2}>
            {depositDetails?.banks?.map((item, index) => (
              <Grid item key={index} xs={4} sm={2}>
                <Box
                  sx={{
                    cursor: "pointer",
                    border: selectedBank?.id === item?.id ? 2 : null,
                    borderRadius: 1,
                    borderColor:
                      selectedBank?.id === item?.id
                        ? theme.palette.primary.main
                        : null,
                  }}
                  onClick={() => setSelectedBank(item)}
                >
                  <Image src={item?.thumbnailUrl} />
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" pl={1} mt={3} flexDirection="column">
            <Typography color="white">
              {t("walletcard.depositpage.bankingdetails")}
            </Typography>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: "30px",
                "& .MuiTextField-root": {
                  pb: "30px",
                },
              }}
            >
              <TextField
                label={t("walletcard.depositpage.bankaccountname")}
                value={
                  selectedBank?.bankAccountName ||
                  depositDetails?.banks[0]?.bankAccountName
                }
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip
                          text={
                            selectedBank?.bankAccountName ||
                            depositDetails?.banks[0]?.bankAccountName
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={t("walletcard.depositpage.bankaccountnumber")}
                value={
                  selectedBank?.bankAccountNumber ||
                  depositDetails?.banks[0]?.bankAccountNumber
                }
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip
                          text={
                            selectedBank?.bankAccountNumber ||
                            depositDetails?.banks[0]?.bankAccountNumber
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={t("walletcard.depositpage.bankreference")}
                value={
                  selectedBank?.bankReference ||
                  depositDetails?.banks[0]?.bankReference
                }
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip
                          text={
                            selectedBank?.bankReference ||
                            depositDetails?.banks[0]?.bankReference
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Typography color="white">
              {t("walletcard.depositpage.depositdetails")}
            </Typography>
            <form onSubmit={handleSubmit(submitDeposit)}>
              <FormTextField
                label={`${t("walletcard.depositpage.amount")} (${
                  process.env.REACT_APP_CURRENCY_CODE
                })`}
                control={control}
                controllerName="amount"
                defaultValue={""}
                placeholder={t("placeholder.minamount")}
                size="small"
              />
              <Grid container my={3}>
                {depositDetails?.defaultAmountSelection?.map((item, index) => (
                  <Grid item>
                    <Button
                      key={index}
                      disableRipple
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        setValue("amount", item.content, {
                          shouldValidate: true,
                        });
                      }}
                    >
                      <Box
                        display="flex"
                        sx={{
                          border: 1,
                          width: "58px",
                          height: "40px",
                          borderColor: "white",
                          boxShadow: 3,
                        }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            color: "white",
                          }}
                        >
                          {item.content}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <FormDatePicker
                control={control}
                label={t("walletcard.depositpage.datetimeonreceipt")}
                controllerName="receiptDateTime"
                time={true}
                size="small"
              />
              <FormFileInput
                label={t("walletcard.depositpage.attachment")}
                control={control}
                controllerName="receipt"
                defaultValue={""}
                InputLabelProps={{
                  shrink: true,
                }}
                type="file"
                margin="dense"
                size="small"
              />
              {/* <FormRadioButton
              title={"Promotion"}
              control={control}
              controllerName="promotion"
              items={radioButton}
            /> */}
              <Box alignSelf="center" mb={3} mt={2}>
                <Button variant="contained" type="submit">
                  {t("walletcard.submit")}
                </Button>
              </Box>
            </form>
            <Typography color="white">
              {t("walletcard.depositpage.friendlyreminder.title")}
            </Typography>
            <Typography color="white" sx={{ textAlign: "justify" }}>
              <ol>
                <li style={{ margin: "16px" }}>
                  {t("walletcard.depositpage.friendlyreminder.firstdesc")}
                </li>
                <li style={{ margin: "16px" }}>
                  {t("walletcard.depositpage.friendlyreminder.seconddesc")}
                </li>
                <li style={{ margin: "16px" }}>
                  {t("walletcard.depositpage.friendlyreminder.thirddesc")}{" "}
                </li>
                <li style={{ margin: "16px" }}>
                  {t("walletcard.depositpage.friendlyreminder.fourthdesc")}
                </li>
                <li style={{ margin: "16px" }}>
                  {t("walletcard.depositpage.friendlyreminder.fifthdesc")}
                </li>
                <li style={{ margin: "16px" }}>
                  {t("walletcard.depositpage.friendlyreminder.sixthdesc")}
                </li>
                <li style={{ margin: "16px" }}>
                  {t("walletcard.depositpage.friendlyreminder.seventhdesc")}
                </li>
              </ol>
            </Typography>
          </Box>
        </Box>
      </TabPanel>
    </Box>
  );
};

export default Deposit;
