import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Menu,
  Button,
  IconButton,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import Image from "./Image";

const DropdownMenu = ({
  icon,
  items,
  children,
  sxButton,
  sxMenu,
  handleOnClickEvent,
  variant,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderItems = () =>
    items.map((item, index) => (
      <MenuItem
        key={index}
        onClick={() => {
          handleOnClickEvent(item);
          handleClose();
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {!item?.icon ? (
            <Image src={item?.thumbnailUrl} height={20} />
          ) : (
            item?.icon
          )}
          <Typography
            textAlign="center"
            ml={item?.icon || item?.thumbnailUrl ? 1 : 0}
            variant="subtitle1"
            sx={{ fontWeight: "300" }}
            color="white"
          >
            {item?.label}
          </Typography>
        </Box>
      </MenuItem>
    ));

  const renderMenu = () => (
    <Menu
      sx={{ mt: "35px", ...sxMenu }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {renderItems()}
    </Menu>
  );

  return (
    <>
      {icon ? (
        <IconButton sx={sxButton} onClick={handleOpen}>
          {children}
          {renderMenu()}
          {/* {children && (anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />)} */}
        </IconButton>
      ) : (
        <Button sx={sxButton} disableRipple onClick={handleOpen}>
          {children}
          {renderMenu()}
          {/* {children && (anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />)} */}
        </Button>
      )}
    </>
  );
};

DropdownMenu.propTypes = {
  icon: PropTypes.bool,
  handleOnClickEvent: PropTypes.func,
  sxButton: PropTypes.object,
  sxMenu: PropTypes.object,
  variant: PropTypes.string,
};

DropdownMenu.defaultProps = {
  icon: false,
  handleOnClickEvent: () => null,
  sxButton: {},
  sxMenu: {},
  variant: "outlined",
};

export default DropdownMenu;
