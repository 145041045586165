import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logout, AccountCircle } from "@mui/icons-material";
import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  useTheme,
  Button,
  Typography,
} from "@mui/material";
import i18n from "i18next";
import { selectCurrentToken } from "../../features/slices/authSlice";
import { logout } from "../../features/slices/authSlice";
import * as Constant from "../../features/constant";
import { useGetProfileQuery } from "../../features/api/userApiSlice";

import Image from "../common/Image";
import DropdownMenu from "../common/DropdownMenu";
import Drawer from "../common/Drawer";
import Backdrop from "../common/Backdrop";

const MobileHeader = ({ background }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);

  const logoutProfileItems = [
    {
      label: i18n.t("walletcard.logout"),
      icon: <Logout sx={{ height: 20 }} />,
    },
  ];

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  if (isUserFetching || isUserLoading) {
    return <Backdrop />;
  }

  const handleLogoutOnClick = (item) => {
    if (item?.label === i18n.t("walletcard.logout")) {
      dispatch(logout());
      navigate(Constant.getPath("/"));
    }
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar
          sx={{
            background: `url('${background}')`,
            height: 0,
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={4} alignSelf="center">
              <Drawer user={user?.result} />
            </Grid>
            <Grid item xs={4}>
              <Image
                src={process.env.REACT_APP_LOGO}
                onClick={() => navigate(Constant.getPath("/"))}
                alt={Constant.ALT_TAG_LOGO}
              />
            </Grid>
            <Grid item xs={4} alignSelf="center" textAlign="right">
              {token && (
                <DropdownMenu
                  sxMenu={{
                    mt: "40px",
                  }}
                  items={logoutProfileItems}
                  handleOnClickEvent={handleLogoutOnClick}
                  icon={true}
                >
                  <AccountCircle />
                </DropdownMenu>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

MobileHeader.propTypes = {
  background: PropTypes.string,
};

MobileHeader.defaultProps = {
  background: "",
};

export default MobileHeader;
