import React from "react";
import PropTypes from "prop-types";
import { FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const FormFileInput = ({
  control,
  defaultValue,
  label,
  controllerName,
  type,
  size,
  placeholder,
  InputProps,
  disabled,
  InputLabelProps,
  margin,
  style,
}) => {
  return (
    <FormControl
      sx={
        Object.keys(style).length !== 0
          ? style
          : {
              display: "flex",
              flexDirection: "column",
              mt: "30px",
            }
      }
    >
      <Controller
        name={controllerName}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              label={label}
              // value={value?.name || ""}
              onChange={(e) => onChange(e.target.files)}
              InputProps={{
                readOnly: true,
              }}
              error={!!error}
              helperText={error ? error.message : null}
              type={type}
              size={size}
              placeholder={placeholder}
              disabled={disabled}
              InputLabelProps={InputLabelProps}
              margin={margin}
            />
            {/* <input
              type="file"
              onChange={(e) => {
                onChange(e.target.files);
              }}
            /> */}
          </>
        )}
        InputProps={InputProps}
      />
    </FormControl>
  );
};

FormFileInput.propTypes = {
  label: PropTypes.string,
  controllerName: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  style: PropTypes.object,
};

FormFileInput.defaultProps = {
  label: "",
  controllerName: "",
  type: "text",
  size: "medium",
  placeholder: "",
  disabled: false,
  margin: "",
  style: {},
};

export default FormFileInput;
