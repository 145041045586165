import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
  Pagination,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DateRangePicker from "../form/DateRangePicker";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { lighten } from "@material-ui/core";

// not going to refactor this part for now
const Transaction = ({ useGetHistoryMutation }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const currentDate = new Date();
  const sevenDaysAgo = new Date();

  const category = [
    { label: t("walletcard.transactionpage.deposit"), value: "Deposit" },
    { label: t("walletcard.transactionpage.withdraw"), value: "Withdraw" },
  ];
  const type = [
    { label: t("walletcard.transactionpage.all"), value: "All" },
    { label: t("walletcard.transactionpage.approved"), value: "Approved" },
    { label: t("walletcard.transactionpage.rejected"), value: "Rejected" },
    { label: t("walletcard.transactionpage.pending"), value: "Pending" },
  ];

  const [transactionHistory, setTransactionHistory] = useState(
    category[0].value
  );
  const [statusType, setStatusType] = useState(type[0].value);
  const [startDate, setStartDate] = useState(
    sevenDaysAgo.setDate(currentDate.getDate() - 7)
  );
  const [endDate, setEndDate] = useState(currentDate);
  const [offset, setOffset] = useState(0);

  const [
    history,
    result,
    // { data, isLoading, isError, isSuccess, error },
  ] = useGetHistoryMutation();

  const handleOnChangeHistory = (event) => {
    setTransactionHistory(event.target.value);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleOnChangeType = (event) => {
    setStatusType(event.target.value);
  };

  const handlePageChange = (e, p) => {
    setOffset(p - 1);
  };

  useEffect(() => {
    if (!(endDate < startDate)) {
      let data = {
        criteria: {
          dateFrom: format(startDate, "dd-MM-yyyy"),
          dateTo: format(endDate, "dd-MM-yyyy"),
          status: statusType,
          type: transactionHistory.toLowerCase(),
        },
        offset: offset,
      };
      history(data).unwrap();
    }
  }, [transactionHistory, startDate, endDate, statusType, offset]);

  return (
    <Container>
      <Typography fontWeight="bold" color="white">
        {" "}
        {t("walletcard.transaction")}
      </Typography>
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "30px",
          "& .MuiTextField-root": {
            pb: "30px",
          },
          "& .MuiSelect": {
            marginBottom: "30px",
          },
        }}
      >
        <InputLabel id="searchingTransaction">
          {t("walletcard.transactionpage.searchingtransaction")}
        </InputLabel>
        <Select
          value={transactionHistory}
          onChange={handleOnChangeHistory}
          label={t("walletcard.transactionpage.searchingtransaction")}
          labelId="searchingTransaction"
          size="small"
        >
          {category.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={mobileView ? "column" : "row"}
        mt="30px"
      >
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          size="small"
        />
        <Box mt={mobileView ? "30px" : null}>
          <FormControl>
            <InputLabel id="type">
              {t("walletcard.transactionpage.type")}
            </InputLabel>
            <Select
              value={statusType}
              onChange={handleOnChangeType}
              label="Type"
              labelId="type"
              size="small"
            >
              {type.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ height: 400, width: "100%", mt: "30px" }}>
        <DataGrid
          sx={{
            "& .MuiDialogContent-root": {
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "12px",
              },
              "::-webkit-scrollbar-track": {
                background: theme.palette.text.disabled,
              },
              "::-webkit-scrollbar-thumb": {
                background: theme.palette.background.paper,
              },
            },
            color: "white",
          }}
          rows={
            result?.data?.result?.content.map((content, idx) => {
              return {
                ...content,
                createdDate: format(
                  new Date(content?.createdDate),
                  "dd-MM-yyyy"
                ),
                id: idx + 1,
              };
            }) || []
          }
          columns={[
            {
              field: "id",
              headerName: t("walletcard.transactionpage.id"),
              width: 150,
              editable: false,
            },
            {
              field: "type",
              headerName: t("walletcard.transactionpage.type"),
              width: 150,
              editable: false,
            },
            {
              field: "amount",
              headerName: t("walletcard.transactionpage.amount"),
              width: 150,
              editable: false,
            },
            {
              field: "status",
              headerName: t("walletcard.transactionpage.status"),
              width: 150,
              editable: false,
            },
            {
              field: "createdDate",
              headerName: t("walletcard.transactionpage.createddate"),
              width: 150,
              editable: false,
            },
            {
              field: "reason",
              headerName: "Reason",
              width: 150,
              editable: false,
            },
          ]}
          hideFooter={true}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          // disableSelectionOnClick
          // isCellEditable={false}
          onPageChange={(newPage) => setOffset(newPage)}
        />
      </Box>
      <Stack mt={2} spacing={2} size="small" alignItems="center">
        <Pagination
          // disabled={selectedAnnouncement.selected ? true : false}
          // onChange={handlePageChange}
          onChange={handlePageChange}
          count={
            result?.data?.result?.totalPages === 0
              ? 1
              : result?.data?.result?.totalPages
          }
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              color: "white",
              "&.Mui-selected": {
                background: lighten(theme.palette.background.paper, 0.02),
                color: "white",
              },
            },
          }}
        />
      </Stack>
    </Container>
  );
};

export default Transaction;
