import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";
import "swiper/css/effect-coverflow";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  EffectCoverflow,
} from "swiper";

import { useTheme, useMediaQuery, Grid, Box, Typography } from "@mui/material";
import Image from "./Image";
import GameCard from "../Game/GameCard";
import { selectCurrentToken } from "../../features/slices/authSlice";
import { openModal, closeModal } from "../../features/slices/modalSlice";
import { useTranslation } from "react-i18next";
import { useLazyGetLaunchGameQuery } from "../../features/api/gameApiSlice";
import Backdrop from "./Backdrop";
import * as Constant from "../../features/constant";

const LiveCasinoCarousel = ({
  data,
  slidesPerView,
  spaceBetween,
  effect,
  pagination,
  style,
  imageHeight,
  imageWidth,
  navigation,
  rows,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const token = useSelector(selectCurrentToken);

  const [
    getLaunchGame,
    {
      data: launchGame,
      isFetching: isLaunchGameFetching,
      isLoading: isLaunchGameLoading,
      isError: isLaunchGameError,
      isSuccess: isLaunchGameSuccess,
      error: launchGameError,
    },
  ] = useLazyGetLaunchGameQuery({ prefetch: true });

  useEffect(() => {
    if (!isLaunchGameLoading && !isLaunchGameFetching && isLaunchGameSuccess) {
      if (launchGame?.result?.gameType === "WEB") {
        window.open(launchGame.result.gameUrl, "_blank");
      }
    }
  }, [isLaunchGameLoading, isLaunchGameFetching, isLaunchGameSuccess]);

  if (isLaunchGameFetching || isLaunchGameLoading) {
    return <Backdrop />;
  }
  return (
    <>
      <Swiper
        effect={effect}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        style={{
          "--swiper-pagination-color": theme.palette.primary.main,
          "--swiper-pagination-bullet-size": "10px",
          "--swiper-navigation-color": theme.palette.primary.main,
        }}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        navigation={navigation}
        pagination={pagination ? { clickable: true } : pagination}
        mousewheel={false}
        keyboard={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[
          Autoplay,
          Navigation,
          Pagination,
          Mousewheel,
          Keyboard,
          EffectCoverflow,
        ]}
      >
        {data?.result?.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                // padding: "0px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!token) {
                  dispatch(
                    openModal({
                      title: t("dialog.proceedtologin"),
                      button: [
                        {
                          label: t("dialog.login"),
                          onSubmit: () => dispatch(closeModal()),
                        },
                      ],
                    })
                  );
                } else {
                  getLaunchGame({
                    id: item?.id,
                    type: item?.gameType,
                    category: "LIVE_CASINO",
                  });
                }
              }}
            >
              <Box overflow="hidden" borderRadius={5}>
                <Image hovered={true} src={item.homeGameImgUrl} alt={Constant.ALT_TAG_GENERAL_PROVIDER + item.name} />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  color={"white"}
                  fontSize={13}
                  fontWeight={800}
                  mt={2}
                >
                  {item.name}
                </Typography>
                {/* <Typography color={"white"} fontSize={10} mt={1}>
                  {item.description}
                </Typography> */}
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

LiveCasinoCarousel.propTypes = {
  data: PropTypes.array,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  effect: PropTypes.string,
  pagination: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string,
  navigation: PropTypes.bool,
  rows: PropTypes.number,
};

LiveCasinoCarousel.defaultProps = {
  data: [],
  slidesPerView: 1,
  spaceBetween: 20,
  effect: null,
  pagination: true,
  style: {
    "--swiper-navigation-color": "#FFF",
    "--swiper-navigation-size": "40px",
    "--swiper-pagination-color": "#FFF",
  },
  onClick: () => null,
  type: null,
  navigation: true,
  rows: 1,
};

export default LiveCasinoCarousel;
