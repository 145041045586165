import React, { useEffect } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  Container,
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Select,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import FormTextField from "../form/FormTextField";
import FormSelect from "../form/FormSelect";
import { setAlert } from "../../features/slices/alertSlice";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import * as Constant from "../../features/constant";

const Withdrawal = ({ withdrawDetails, useWithdrawMutation, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const withdrawalSchema = yup.object().shape({
    bankAccount: yup.string().required(t("validation.bankname")),
    bankAccountName: yup.string().required(t("validation.bankaccname")),
    bankAccountNo: yup.string().required(t("validation.bankaccno")),
    amount: yup
      .number()
      .typeError(t("validation.amountonly"))
      .min(200, "Min 200.00")
      .required(t("validation.amountisrequired")),
  });

  const { control, handleSubmit, reset, getValues } = useForm({
    resolver: yupResolver(withdrawalSchema),
  });

  const [withdraw, { data, isLoading, isError, isSuccess, error }] =
    useWithdrawMutation();

  const submitWithdrawal = async (data) => {
    const formData = new FormData();
    formData.append("amount", data.amount);
    formData.append("bankAccountName", data.bankAccountName);
    formData.append("bankAccountNumber", data.bankAccountNo);
    formData.append("bankCode", data.bankAccount);

    let newData = {
      amount: data.amount,
      bankAccountName: data.bankAccountName,
      bankAccountNumber: data.bankAccountNo,
      bankCode: data.bankAccount,
    };

    await withdraw(newData).unwrap();
    navigate(Constant.getPath('/'));
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.withdrawalpage.successmessage"),
          variant: "success",
        })
      );
      reset();
    }
    if (!isLoading && isError) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error?.data?.message,
          variant: "error",
        })
      );
      reset();
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <Container>
      <Typography fontWeight="bold" color="white">
        {t("walletcard.withdrawal")}
      </Typography>
      <Box display="flex" flexDirection="column">
        <form onSubmit={handleSubmit(submitWithdrawal)}>
          <FormSelect
            label={t("walletcard.withdrawalpage.bankname")}
            control={control}
            controllerName="bankAccount"
            defaultValue={withdrawDetails?.withdrawDetails?.bankCode}
            items={withdrawDetails?.banks
              ?.filter((item) => item.status === "ACTIVE")
              .map((item) => item.content)}
            size="small"
            disabled={withdrawDetails?.withdrawDetails?.bankCode ? true : false}
          />
          <FormTextField
            label={t("walletcard.withdrawalpage.bankaccountname")}
            control={control}
            controllerName="bankAccountName"
            defaultValue={user?.fullname}
            disabled={true}
            size="small"
          />
          <FormTextField
            label={t("walletcard.withdrawalpage.bankaccountnumber")}
            control={control}
            controllerName="bankAccountNo"
            defaultValue={withdrawDetails?.withdrawDetails?.bankAccountNumber}
            size="small"
            disabled={
              withdrawDetails?.withdrawDetails?.bankAccountNumber ? true : false
            }
          />
          <FormTextField
            label={t("walletcard.withdrawalpage.amount")}
            control={control}
            controllerName="amount"
            defaultValue={""}
            placeholder="Min 200.00"
            size="small"
          />
          <Box alignSelf="center" mb="30px" mt="30px">
            <Button variant="contained" type="submit" disabled={isLoading}>
              {t("walletcard.submit")}
            </Button>
          </Box>
        </form>
        <Typography color="white">
          {t("walletcard.withdrawalpage.promotionrolloverstatus")}
        </Typography>
        <Box mt="15px">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                flexDirection: "row-reverse",
                "& .MuiAccordionSummary-content": {
                  justifyContent: "space-between",
                },
              }}
            >
              <Typography sx={{ ml: "10px" }} color="white">
                {t("walletcard.withdrawalpage.rolloverrequirement")}
              </Typography>
              <Typography color="white">
                {withdrawDetails?.promotionStatus?.withdrawalMinRollover ||
                  "NA"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ ml: "33px" }}>
              <Box display="flex" justifyContent="space-between">
                <Typography color="white">
                  {t("walletcard.withdrawalpage.promotionrollover")}
                </Typography>
                <Typography color="white">
                  {process.env.REACT_APP_CURRENCY_CODE +
                    " " +
                    withdrawDetails?.promotionStatus?.currentRollover || "NA"}
                </Typography>
              </Box>
            </AccordionDetails>
            {/* <AccordionDetails>
              <Box mt="15px" display="flex" justifyContent="space-between">
                <Typography>Promotion Rollover</Typography>
                <Typography>0.00</Typography>
              </Box>
            </AccordionDetails>
            <AccordionDetails>
              <Box mt="15px" display="flex" justifyContent="space-between">
                <Typography>Latest Deposit Rollover</Typography>
                <Typography>0.00</Typography>
              </Box>
            </AccordionDetails> */}
          </Accordion>
          {/* <Divider />
          <Box
            mt="15px"
            px="15px"
            display="flex"
            justifyContent="space-between"
          >
            <Typography>Accumulated Rollover</Typography>
            <Typography>MYR 0.00</Typography>
          </Box> */}
        </Box>
        <Box mt="15px">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                flexDirection: "row-reverse",
                "& .MuiAccordionSummary-content": {
                  justifyContent: "space-between",
                },
              }}
            >
              <Typography sx={{ ml: "10px" }} color="white">
                {t("walletcard.withdrawalpage.turnoverrequirement")}
              </Typography>
              <Typography color="white">
                {withdrawDetails?.promotionStatus?.withdrawalMinTurnoverAmount
                  ? process.env.REACT_APP_CURRENCY_CODE +
                    " " +
                    withdrawDetails?.promotionStatus
                      ?.withdrawalMinTurnoverAmount
                  : "NA"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ ml: "33px" }}>
              <Box display="flex" justifyContent="space-between">
                <Typography color="white">
                  {t("walletcard.withdrawalpage.promotionturnover")}
                </Typography>
                <Typography color="white">
                  {withdrawDetails?.promotionStatus?.currentTurnoverAmount
                    ? process.env.REACT_APP_CURRENCY_CODE +
                      " " +
                      withdrawDetails?.promotionStatus?.currentTurnoverAmount
                    : "NA"}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box mt="40px">
          <Typography color="white">
            {t("walletcard.withdrawalpage.friendlyreminder.title")}
          </Typography>
          <Typography color="white" sx={{ textAlign: "justify" }}>
            <ol>
              <li style={{ margin: "16px" }}>
                {t("walletcard.withdrawalpage.friendlyreminder.firstdesc")}
              </li>
              <li style={{ margin: "16px" }}>
                {t("walletcard.withdrawalpage.friendlyreminder.seconddesc")}
              </li>
              <li style={{ margin: "16px" }}>
                {t("walletcard.withdrawalpage.friendlyreminder.thirddesc")}{" "}
              </li>
              <li style={{ margin: "16px" }}>
                {t("walletcard.withdrawalpage.friendlyreminder.fourthdesc")}
              </li>
            </ol>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Withdrawal;
