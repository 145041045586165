import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../features/slices/modalSlice";
import { selectModalIsOpen } from "../../features/slices/modalSlice";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { lighten } from "@material-ui/core";

const ModalDialog = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isOpen, modalProps } = useSelector((state) => state.modal);
  const [scroll, setScroll] = useState("paper");

  const handleClose = () => {
    dispatch(closeModal());
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, []);

  return (
    <div>
      <Dialog
        open={isOpen}
        maxWidth="md"
        onClose={handleClose}
        scroll={scroll}
        sx={{
          "& .MuiPaper-root": {
            bgcolor: theme.palette.background.paper,
          },
          "& .MuiDialogContent-root": {
            overflowY: "auto",
            "::-webkit-scrollbar": {
              width: "12px",
            },
            "::-webkit-scrollbar-track": {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.text.disabled
                  : theme.palette.background.paper,
            },
            "::-webkit-scrollbar-thumb": {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.background.paper
                  : theme.palette.text.disabled,
            },
          },
        }}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="white"
          >
            {modalProps?.title}
            <IconButton sx={{ ml: 2 }} onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="white" ref={descriptionElementRef} tabIndex={-1}>
            {modalProps.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {modalProps?.button?.map((button, idx) => (
            <>
              <Button
                key={idx}
                variant="contained"
                type="submit"
                onClick={button?.onSubmit}
                disabled={isOpen ? button?.disabled : true}
                sx={{
                  fontWeight: 600,
                  "&.Mui-disabled": {
                    backgroundColor: lighten(
                      theme.palette.background.paper,
                      0.2
                    ),
                    color: lighten(theme.palette.background.paper, 0.02),
                  },
                }}
              >
                {button?.label}
              </Button>
            </>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalDialog.propTypes = {};

ModalDialog.defaultProps = {};

export default ModalDialog;
