import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Container,
  useTheme,
} from "@mui/material";
import Backdrop from "../components/common/Backdrop";
import Image from "../components/common/Image";
import { useGetRankInfoQuery } from "../features/api/userApiSlice";
import { useTranslation } from "react-i18next";
import * as Constant from "../features/constant";

const Vip = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    data: rankInfo,
    isFetching: isRankInfoFetching,
    isLoading: isRankInfoLoading,
    isError: isRankInfoError,
    isSuccess: isRankInfoSuccess,
    error: rankInfoError,
  } = useGetRankInfoQuery({ prefetch: true });

  return (
    <>
      {(isRankInfoLoading || isRankInfoFetching) && <Backdrop />}
      {!isRankInfoLoading && isRankInfoSuccess && (
        <Box
          sx={{
            background: `url('${rankInfo?.webBackground[0]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box py={{ xs: 10, sm: 3, lg: 3, xl: 3 }}>
              <Typography
                variant="h4"
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
                color="white"
              >
                {t("home.vip.title")}{" "}
              </Typography>
              <Box py={4}>
                <TableContainer component={Paper}>
                  <Table aria-label="VIP table" sx={{
                    '& .MuiTableCell-sizeMedium': {
                    padding: '8px',
                  }}}>
                    <TableHead>
                      <TableRow sx={{
                          background: theme.palette.primary.main,
                          border: "none"
                        }}>
                        <TableCell sx={{ border: "none" }}>
                          <Typography color="white" align="center" sx={{ fontWeight: "bold" }}>
                            {t("home.vip.privileges")}
                          </Typography>
                        </TableCell>
                        {Object.keys(rankInfo?.result?.rankDetails).map(
                          (rankDetail, idx) => (
                            <TableCell key={idx} align="center" sx={{ border: "none" }}>
                              <Box
                                component="div"
                                display="inline-block"
                                sx={{ textAlign: "center" }}
                              >
                                <Typography sx={{ fontWeight: "bold" }} color="white">
                                  {rankDetail}
                                </Typography>
                              </Box>
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(rankInfo?.result?.privileges).map(
                        (privilege, idx) => (
                          <>
                            <TableRow key={idx} sx={{ background: idx % 2 == 1 ? theme.palette.background.paper : theme.palette.background.secondaryPaper }}>
                              <TableCell component="th" scope="row" sx={{ border: "none" }}>
                                <Box display="flex" alignItems="center" pl={2}>
                                  <Image
                                    src={
                                      rankInfo?.result?.privileges[privilege].logo
                                    }
                                    height="35px"
                                    width="35px"
                                    alt={Constant.ALT_TAG_VIP + privilege}
                                  />
                                  <Typography
                                    sx={{
                                      color: "white",
                                      textTransform: "uppercase"
                                    }}
                                    ml={1}
                                  >
                                    {privilege}
                                  </Typography>
                                </Box>
                              </TableCell>
                              {Object.keys(rankInfo?.result?.rankDetails).map((detail, idx) => (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    sx={{ fontWeight: "normal", color: "white", border: "none" }}>
                                    {rankInfo?.result?.rankDetails[detail][privilege]}
                                  </TableCell>
                              ))}
                            </TableRow>
                          </>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Vip;
