import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Container,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Grid,
  Button,
  darken,
  useMediaQuery,
} from "@mui/material";
import Image from "../components/common/Image";
import { useTranslation } from "react-i18next";
import AccordionComponent from "../components/common/Accordion";
import { openModal, closeModal } from "../features/slices/modalSlice";
import * as Constant from "../features/constant";

const Affiliate = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const faqs = [
    {
      question: t("affiliate.faq.1.title"),
      answer: t("affiliate.faq.1.desc"),
    },
    {
      question: t("affiliate.faq.2.title"),
      answer: t("affiliate.faq.2.desc"),
    },
    {
      question: t("affiliate.faq.3.title"),
      answer: t("affiliate.faq.3.desc"),
    },
    {
      question: t("affiliate.faq.4.title"),
      answer: t("affiliate.faq.4.desc"),
    },
    {
      question: t("affiliate.faq.5.title"),
      answer: t("affiliate.faq.5.desc"),
    },
    {
      question: t("affiliate.faq.6.title"),
      answer: t("affiliate.faq.6.desc"),
    },
    {
      question: t("affiliate.faq.7.title"),
      answer: t("affiliate.faq.7.desc"),
    },
    {
      question: t("affiliate.faq.8.title"),
      answer: t("affiliate.faq.8.desc"),
    },
  ];

  return (
    <Box
      sx={{
        background: `#1E1F22`,
        minHeight: "100vh",
      }}
      py={{ xs: 6, sm: 0, lg: 0, xl: 0 }}
    >
      <Container>
        <Box
          py={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="32px"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="32px"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography
                    fontSize="24px"
                    fontStyle="italic"
                    fontWeight={800}
                    color="white"
                    fontFamily="Montserrat"
                  >
                    {t("affiliate.affiliateprogram")}
                  </Typography>
                  <Typography
                    fontSize="24px"
                    // color={`linear-gradient(180deg, #FFE04B, #DD981D, #FBEF4B)`}
                    fontWeight={800}
                    fontFamily="Montserrat"
                    sx={{
                      backgroundImage: `linear-gradient(180deg, #FFE04B, #DD981D, #FBEF4B)`,
                      backgroundSize: "100%",
                      backgroundRepeat: "repeat",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    {t("affiliate.unlimitedcommission")}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    background: `linear-gradient(180deg, #298A31, #56AD12)`,
                    width: "30%",
                    fontFamily: "Montserrat",
                  }}
                  onClick={() => navigate(Constant.getPath("/register"))}
                >
                  {t("affiliate.signupbutton")}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                width="100%" // Set the width to 100% to make the image responsive
                height="auto" // Set height to auto to maintain aspect ratio
                style={{ objectFit: "cover" }}
                src={process.env.REACT_APP_AFFILIATE + "/affiliate_banner.png"}
                alt={Constant.ALT_TAG_AFFL_IMG}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                sx={{
                  backgroundColor: "#2C3035",
                  p: 2,
                }}
              >
                <Grid item xs={12} md={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box display="flex" flexDirection="column" gap="24px">
                      <Typography
                        fontFamily="Montserrat"
                        fontSize="18px"
                        fontWeight={600}
                        color="white"
                      >
                        {t("affiliate.weeklycommissionpayment")}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#424242",
                          color: "white",
                          fontFamily: "Montserrat",
                        }}
                        onClick={() => {
                          dispatch(
                            openModal({
                              title: t("affiliate.paymentdetails"),
                              content: (
                                <>
                                  <p>Coming Soon</p>
                                </>
                              ),
                            })
                          );
                        }}
                      >
                        {t("affiliate.seepaymentdetailsbutton")}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 1,
                      // height: "100%", // Ensure the container takes full height
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_AFFILIATE +
                        "/WEEKLY_COMMISSION_PAYMENT.png"
                      }
                      alt={Constant.ALT_TAG_AFFL_IMG}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                sx={{
                  backgroundColor: "#2C3035",
                  p: 2,
                }}
              >
                <Grid item xs={12} md={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box display="flex" flexDirection="column" gap="24px">
                      <Typography
                        fontFamily="Montserrat"
                        fontSize="18px"
                        fontWeight={600}
                        color="white"
                      >
                        {t("affiliate.revenewshare")}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#424242",
                          color: "white",
                          fontFamily: "Montserrat",
                        }}
                        onClick={() => {
                          dispatch(
                            openModal({
                              title: t("affiliate.paymentdetails"),
                              content: (
                                <>
                                  <p>Coming Soon</p>
                                </>
                              ),
                            })
                          );
                        }}
                      >
                        {t("affiliate.seepaymentdetailsbutton")}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 1,
                      // height: "100%", // Ensure the container takes full height
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_AFFILIATE +
                        "/SHARE_FOR_AFFILIATE.png"
                      }
                      alt={Constant.ALT_TAG_AFFL_IMG}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "250px", // Set the width and height to create a circle
                  height: "250px",
                  borderRadius: "50%", // Make it a circle
                  backgroundColor: "#292929",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  gap: "16px",
                }}
              >
                <Image
                  width="100px"
                  height="100px"
                  src={process.env.REACT_APP_AFFILIATE + "/icon_1.png"}
                  alt={Constant.ALT_TAG_AFFL_IMG}
                />
                <Typography
                  fontFamily="Montserrat"
                  fontSize="14px"
                  color="white"
                >
                  {t("affiliate.features.smartlink")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "250px", // Set the width and height to create a circle
                  height: "250px",
                  borderRadius: "50%", // Make it a circle
                  backgroundColor: "#292929",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  gap: "16px",
                }}
              >
                <Image
                  width="100px"
                  height="100px"
                  src={process.env.REACT_APP_AFFILIATE + "/icon_2.png"}
                  alt={Constant.ALT_TAG_AFFL_IMG}
                />
                <Typography
                  fontFamily="Montserrat"
                  fontSize="14px"
                  color="white"
                >
                  {t("affiliate.features.greatmarketingmarket")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "250px", // Set the width and height to create a circle
                  height: "250px",
                  borderRadius: "50%", // Make it a circle
                  backgroundColor: "#292929",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  gap: "16px",
                }}
              >
                <Image
                  width="100px"
                  height="100px"
                  src={process.env.REACT_APP_AFFILIATE + "/icon_3.png"}
                  alt={Constant.ALT_TAG_AFFL_IMG}
                />
                <Typography
                  fontFamily="Montserrat"
                  fontSize="14px"
                  color="white"
                >
                  {t("affiliate.features.personalaffiliatemanager")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "250px", // Set the width and height to create a circle
                  height: "250px",
                  borderRadius: "50%", // Make it a circle
                  backgroundColor: "#292929",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  gap: "16px",
                }}
              >
                <Image
                  width="100px"
                  height="100px"
                  src={process.env.REACT_APP_AFFILIATE + "/icon_4.png"}
                  alt={Constant.ALT_TAG_AFFL_IMG}
                />
                <Typography
                  fontFamily="Montserrat"
                  fontSize="14px"
                  color="white"
                >
                  {t("affiliate.features.greatuserinterface")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography
            fontFamily="Montserrat"
            fontSize="16px"
            alignSelf="flex-start"
            fontWeight={600}
            color={theme.palette.primary.main}
          >
            {t("affiliate.tnc.title")}
          </Typography>
          <Typography
            fontFamily="Montserrat"
            alignSelf="flex-start"
            color="white"
            sx={{ textAlign: "justify" }}
          >
            <ol>
              <li style={{ margin: "16px" }}>{t("affiliate.tnc.1")}</li>
              <li style={{ margin: "16px" }}>{t("affiliate.tnc.2")}</li>
              <li style={{ margin: "16px" }}>{t("affiliate.tnc.3")}</li>
              <li style={{ margin: "16px" }}>{t("affiliate.tnc.4")}</li>
              <li style={{ margin: "16px" }}>{t("affiliate.tnc.5")}</li>
              <li style={{ margin: "16px" }}>{t("affiliate.tnc.6")}</li>
              <li style={{ margin: "16px" }}>{t("affiliate.tnc.7")}</li>
            </ol>
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontSize="16px"
            fontWeight={600}
            color={"white"}
          >
            {t("affiliate.faq.title")}
          </Typography>
          <Box>
            {faqs.map((a, i) => (
              <AccordionComponent title={a.question} fontFamily={"Montserrat"}>
                {a.answer}
              </AccordionComponent>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Affiliate;
