import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

const AccordionComponent = ({ title, children, fontFamily }) => {
  return (
    <Accordion itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
      <AccordionSummary expandIcon={<KeyboardArrowDown />} >
        <Typography fontFamily={fontFamily} color="white" itemprop="name">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
        <Typography fontFamily={fontFamily} color="white" itemprop="text">
          {children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

AccordionComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string,
};

AccordionComponent.defaultProps = {
  title: null,
  children: null,
};

export default AccordionComponent;
