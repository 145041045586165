export const ALT_TAG_LOGO = "brand-logo";
export const ALT_TAG_BANNER = "banner";
export const ALT_TAG_JACKPOT = "jackpot";
export const ALT_TAG_STEPS = "steps";
export const ALT_TAG_ICON_SLOT = "icon-slot";
export const ALT_TAG_ICON_CASINO = "icon-casino";
export const ALT_TAG_ICON_SPORT = "icon-sport";
export const ALT_TAG_ICON_FISHING = "icon-fishing";
export const ALT_TAG_ICON_SABUNG = "icon-sabung";
export const ALT_TAG_ICON_VIP = "icon-vip";
export const ALT_TAG_ICON_PROMO = "icon-promo";
export const ALT_TAG_ADS_AFFL = "ads-affiliate";
export const ALT_TAG_ADS_PROMO = "ads-promo";
export const ALT_TAG_HOME_ADS = "home-ads";
export const ALT_TAG_HOME_HOT_GAME = "home-hot-game";
export const ALT_TAG_GENERAL_GAME = "game-";
export const ALT_TAG_GENERAL_PROVIDER = "provider-";
export const ALT_TAG_FOOTER_LICENSE = "Gaming Curacao";
export const ALT_TAG_FOOTER_CERT = {
    0: "bmm testlabs",
    1: "tst globals",
    2: "pagcor",
    3: "reco cert",
    4: "itech"
};

export const ALT_TAG_FOOTER_PAYMENT = {
    0: "zinpay",
    1: "quickpay",
    2: "gcash"
};
export const ALT_TAG_FOOTER_SECURITY = {
    0: "iovation"
};
export const ALT_TAG_FOOTER_RESP_GAMING = {
    0:"responsible gaming",
    1:"responsible gaming",
    2:"responsible gaming",
};
export const ALT_TAG_CATEGORY_BANNER = "category-banner-";
export const ALT_TAG_BONUS_IMG = "bonus-image";
export const ALT_TAG_VIP = "vip-";
export const ALT_TAG_AFFL_IMG = "affiliate-image";
export const ALT_TAG_ICON_DEPOSIT = "icon-deposit";
export const ALT_TAG_ICON_WITHDRAW = "icon-withdraw";
export const ALT_TAG_ICON_AFFL = "icon-affiliate";
export const COUNTRY_LANGUAGE_PATH_PARAMS = {
    PH: { en: "en-ph", ph: "tl-ph" }
};

export const getPath = (path) => {
    if (process.env.REACT_APP_LANG_PATH_PARAM) {
        let lang = localStorage.getItem("language") || "en";
        if (!path.startsWith("/")) {
            path = "/" + path;
        }
        lang = COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY][lang];
        return "/" + lang + path;
    }
    else {
        return path;
    }
}