import React, { useEffect, useState } from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import * as Constant from "../../features/constant";
import {
  AppBar,
  Toolbar,
  useTheme,
  Grid,
  Container,
  Box,
  Button,
  useMediaQuery,
  Typography,
  darken,
  IconButton,
} from "@mui/material";
import {
  Home,
  Discount,
  Diamond,
  AccountBalance,
  LocalAtm,
  Diversity3,
} from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { selectCurrentToken } from "../../features/slices/authSlice";
import { setAlert } from "../../features/slices/alertSlice";
import { setCredentials } from "../../features/slices/authSlice";

import { useGetGameCategoryQuery } from "../../features/api/gameApiSlice";
import { useGetProfileQuery } from "../../features/api/userApiSlice";
import { useLoginMutation } from "../../features/api/authApiSlice";
import { useGetLanguagesQuery } from "../../features/api/publicApiSlice";
import { apiSlice } from "../../features/api/apiSlice";

import Image from "../common/Image";
import UserCard from "../common/UserCard";
import FormTextField from "../form/FormTextField";
import DesktopHeaderItems from "./DesktopHeaderItems";

import Backdrop from "../common/Backdrop";
import i18n from "i18next";
import LanguageDropdown from "../common/LanguageDropdown";

const PATH = process.env.PUBLIC_URL;

const DesktopHeader = ({ background }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector(selectCurrentToken);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabletView = useMediaQuery(theme.breakpoints.down("md"));
  const [selected, setSelected] = useState("/");

  const headerItems = [
    {
      label: i18n.t("header.home"),
      code: "home"
    },
    {
      label: i18n.t("header.promotion"),
      code: "promotion"
    },
    {
      label: i18n.t("header.vip"),
      code: "vip"
    },
    {
      label: i18n.t("header.affiliate"),
      code: "affiliate"
    },
  ];

  const headerProfileItems = [
    {
      label: i18n.t("walletcard.deposit"),
      code: "Deposit",
      icon: <AccountBalance display="block" pb={0} mb={0} />,
    },
    {
      label: i18n.t("walletcard.withdrawal"),
      code: "Withdrawal",
      icon: <LocalAtm display="block" pb={0} mb={0} />,
    },
  ];

  const loginSchema = yup.object().shape({
    username: yup.string().required(t("validation.username")),
    password: yup.string().required(t("validation.password")),
  });

  const {
    data: gameCategory,
    isFetching: isGameCategoryFetching,
    isLoading: isGameCategoryLoading,
    isSuccess: isGameCategorySuccess,
  } = useGetGameCategoryQuery({ prefetch: true });

  const {
    data: languages,
    isFetching: isLanguagesFetching,
    isLoading: isLanguagesLoading,
    isSuccess: isLanguagesSuccess,
  } = useGetLanguagesQuery({ prefetch: true });

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  const [login, { data, isLoading, isError, isSuccess, error, reset }] =
    useLoginMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (searchParams.get("refcode")) {
      localStorage.setItem("refCode", searchParams.get("refcode"));
      // dispatch(openRegisterModal());
    }
  }, []);

  useEffect(() => {
    // Check if the current URL path matches the header's path
    if (location.pathname !== selected) {
      setSelected(location.pathname);
    }
  }, [location.pathname]);

  const submitForm = async (data) => {
    let formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("grant_type", "password");

    const userData = await login(formData).unwrap();
    dispatch(setCredentials({ ...userData }));
  };

  if (isSubmitting && !isLoading && isSuccess && !token) {
    dispatch(
      setAlert({
        isOpen: true,
        message: t("header.loginsuccessmessage"),
        variant: "success",
      })
    );
    reset();
    navigate(Constant.getPath("/"));
    setTimeout(() => {
      window.location.reload();
     }, 1000);
  }

  if (isSubmitting && isError) {
    dispatch(
      setAlert({
        isOpen: true,
        message: error.data.error_description,
        variant: "error",
      })
    );
    reset();
  }

  if (
    isGameCategoryFetching ||
    isGameCategoryLoading ||
    isUserFetching ||
    isUserLoading
  ) {
    return <Backdrop />;
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar
          sx={{
            background: `url('${background}'), ${"white"}`,
            minHeight: "90px !important",
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
              <Image
                width="214px"
                src={process.env.REACT_APP_LOGO}
                height="60px"
                onClick={() => navigate(Constant.getPath("/"))}
                alt={Constant.ALT_TAG_LOGO}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {!token ? (
                <Box display="flex" flexDirection="column">
                  <Box mb={1} alignSelf={tabletView && "flex-end"}>
                    <a
                      style={{ color: theme.palette.primary.main }}
                      href="/forgotPassword"
                    >
                      <Typography fontSize={13}>
                        {" "}
                        {t("header.forgotpassword")}
                      </Typography>
                    </a>
                  </Box>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <Box
                      display="flex"
                      justifyContent={tabletView && "flex-end"}
                    >
                      <FormTextField
                        style={{ mr: 1, width: tabletView ? "20%" : null }}
                        label={t("header.username")}
                        control={control}
                        controllerName="username"
                        size="small"
                      />
                      <FormTextField
                        style={{ mr: 1, width: tabletView ? "20%" : null }}
                        label={t("header.password")}
                        control={control}
                        controllerName="password"
                        type="password"
                        size="small"
                      />
                      <Box>
                        <Button
                          variant="contained"
                          sx={{
                            mr: 1,
                          }}
                          type="submit"
                        >
                          {t("header.login")}
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor:
                              theme.palette.button.secondary.main,
                            ":hover": {
                              bgcolor: darken(
                                theme.palette.button.secondary.main,
                                0.2
                              ),
                            },
                          }}
                          onClick={() => navigate(Constant.getPath("/register"))}
                        >
                          {t("header.signup")}
                        </Button>
                      </Box>
                      <Box>
                        <LanguageDropdown languages={languages?.result} />
                      </Box>
                    </Box>
                  </form>
                </Box>
              ) : (
                <>
                  {token &&
                    headerProfileItems.map((item, index) => (
                      <Button
                        variant="contained"
                        sx={{
                          ml: 3,
                          backgroundColor:
                            index === 0 && theme.palette.button.secondary.main ||
                            index === 1 && theme.palette.button.danger.main,

                          ":hover": {
                            borderColor:
                              index === 0 &&
                              theme.palette.button.secondary.main,
                            bgcolor:
                              index === 0 &&
                              darken(theme.palette.button.secondary.main, 0.2),
                          },
                        }}
                        onClick={() => {
                          navigate(Constant.getPath("/profileWallet"), {
                            state: {
                              screen: item.code,
                            },
                          });
                        }}
                      >
                        {item.label}
                      </Button>
                    ))}
                  <UserCard user={user?.result} />
                  <IconButton
                    onClick={() =>
                      dispatch(apiSlice.util.invalidateTags(["Profile"]))
                    }
                  >
                    <Image
                      width="20px"
                      height="20px"
                      src={PATH + "/assets/img/wallet/wallet_card/Refresh.png"}
                    />
                  </IconButton>
                  <Box>
                    <LanguageDropdown languages={languages?.result} />
                  </Box>
                </>
              )}
            </Box>
          </Container>
        </Toolbar>
        <Toolbar
          sx={{
            backgroundColor: theme.palette.primary.main,
            minHeight: "38px !important",
          }}
        >
          <Container>
            <Box mt={1} display="flex" justifyContent="space-around">
              {headerItems.map((item, index) => {
                if (index === 0) {
                  return (
                    <DesktopHeaderItems
                      label={item?.label}
                      selectedPath={selected === Constant.getPath("/") ? true : false}
                      setSelectedPath={setSelected}
                    />
                  );
                }
              })}
              {gameCategory?.result.map((item, index) => {
                return (
                  <DesktopHeaderItems
                    label={item?.label}
                    code={item?.code}
                    selectedPath={
                      selected === Constant.getPath("/game/" + item.code?.toLowerCase().replaceAll("_", "-"))
                        ? true
                        : false
                    }
                    setSelectedPath={setSelected}
                  />
                );
              })}
              {headerItems.map((item, index) => {
                if (index !== 0) {
                  return (
                    <DesktopHeaderItems
                      label={item?.label}
                      selectedPath={
                        selected === Constant.getPath("/" + item.code?.toLowerCase())
                          ? true
                          : false
                      }
                      setSelectedPath={setSelected}
                    />
                  );
                }
              })}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
};

DesktopHeader.propTypes = {
  background: PropTypes.string,
};

DesktopHeader.defaultProps = {
  background: "",
};

export default DesktopHeader;
