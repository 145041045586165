import React from "react";
import { Phone } from "@mui/icons-material";
import { Box, Typography, useTheme, Grid } from "@mui/material";
import Image from "./Image";
import { useGetCommonBackgroundQuery } from "../../features/api/publicApiSlice";

const SideNav = () => {
  const theme = useTheme();

  const {
    data: commonBackground,
    isLoading: isCommonBackgroundLoading,
    isSuccess: isCommonBackgroundSuccess,
  } = useGetCommonBackgroundQuery({ prefetch: true });

  return (
    <Box
      component="div"
      sx={{
        position: "fixed",
        zIndex: "99",
        top: "40%",
        right: "0",
        transition: "0.3s",
        height: "120px",
        width: "40px",
        boxShadow: "1px 1px #00000020",
        color: "black",
        background: `linear-gradient(180deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark})`,
        ":hover": {
          width: "240px",
        },
      }}
    >
      <Box
        component="div"
        sx={{
          position: "fixed",
          textAlign: "center",
          width: "120px",
          paddingTop: "80px",
          transform: "rotate(90deg)",
        }}
      >
        <Typography sx={{ ml: "10px" }}>
          <Phone sx={{ verticalAlign: "middle", mr: "5px", color: "black" }} />
          Contact Us
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          position: "relative",
          padding: "10px",
          display: "inline-block",
          width: "200px",
          height: "250px",
          bgcolor: theme.palette.background.paper,
          boxShadow: "1px 1px #00000020",
          marginLeft: "40px",
        }}
      >
        {/* content goes here */}
        {!isCommonBackgroundLoading && isCommonBackgroundSuccess && (
          <Grid container spacing={0.5} pl={1}>
            {Object.keys(commonBackground?.result)?.map((contact) => (
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Image
                    height="35px"
                    width="35px"
                    src={commonBackground?.result[contact]?.icon}
                  />
                  <Typography pl={2} color="white">
                    {commonBackground?.result[contact]?.value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default SideNav;
