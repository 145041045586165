import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  Box,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";

const FormSelect = ({
  control,
  defaultValue,
  items,
  label,
  controllerName,
  type,
  size,
  placeholder,
  disabled,
}) => {
  return (
    <FormControl
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: "30px",
      }}
    >
      <Controller
        name={controllerName}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              select
              label={label}
              value={value ? value : ""}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              type={type}
              size={size}
              placeholder={placeholder}
              disabled={disabled}
              // disabled={disabled}
              // InputLabelProps={InputLabelProps}
              // margin={margin}
              fullWidth
            >
              {items.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      />
    </FormControl>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string,
  controllerName: PropTypes.string,
  items: PropTypes.array,
  type: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
};

FormSelect.defaultProps = {
  label: "",
  controllerName: "",
  items: [],
  type: "text",
  size: "medium",
  placeholder: "",
};

export default FormSelect;
